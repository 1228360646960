<!--
 * @Author: your name
 * @Date: 2021-03-08 10:36:41
 * @LastEditTime: 2021-04-30 14:44:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\backto2\index.vue
-->
<template>
  <div class="theallmsgclass">
    <navigation msg="选择产品包">
      <!-- <span
        :class="menuindex != 0 ? 'dingyue2' : 'dingyue'"
        @click="$router.push('/dingyue')"
        >订阅设置</span
      > -->
    </navigation>
    <div>
      <div>
        <van-sticky>
          <div class="topall">
            <van-tabs
              :color="'#FF6D20'"
              :title-active-color="'#303133'"
              :title-inactive-color="'#606266'"
              :swipe-threshold="3"
              :duration="0.5"
              @click="tabclick"
            >
              <van-tab v-bind:key="index" v-for="(item, index) in menulist">
                <template #title>
                  <div style="display: flex; align-items: center">
                    {{ item.classify_name }}
                  </div>
                </template>
              </van-tab>
            </van-tabs>
          </div>
          <div class="menuthecla">
            <div class="menuclass_1">
              <div
                @click="getthemsg(item)"
                v-for="(item, index) in taoarray"
                v-bind:key="index"
                :class="
                  setwomenu == item.industry_name
                    ? 'menuchildclass menuchildclass2'
                    : 'menuchildclass'
                "
              >
                {{ item.industry_name }}
              </div>
            </div>
            <div class="imgback">
              <img
                @click="oneMenuShow = true"
                class="otherclass"
                src="../../assets/other.png"
                alt=""
              />
            </div>
          </div>
        </van-sticky>
      </div>
      <div class="liucheng">
        <div @click="sqmeth" class="limc">
          <div>使用流程</div>
          <van-icon
            v-show="issq == true"
            size=".3rem"
            color="rgba(254, 89, 3, 0.75)"
            name="arrow-down"
            style="margin-left: 0.1rem"
          />
          <van-icon
            v-show="issq == false"
            size=".3rem"
            color="rgba(254, 89, 3, 0.75)"
            name="arrow-up"
            style="margin-left: 0.1rem"
          />
        </div>
        <div :style="sqstyle" class="toall">
          <div>1</div>
          <img src="../../assets/next.png" alt="" />
          <div>2</div>
          <img src="../../assets/next.png" alt="" />
          <div>3</div>
          <img src="../../assets/next.png" alt="" />
          <div>4</div>
        </div>
      </div>
      <div class="themenuall">
        <div class="msgclass">
          <div v-for="(item, index) in taoarray_two" v-bind:key="index">
            <!-- <div v-show="item.setMeal.length > 0" class="menuclassthe">
            <div class="dianclass"></div>
            <div class="dianclass2"></div>
            <div>{{ item.industry_name }}</div>
          </div> -->
            <div
              v-for="(itemes, indexes) in item.setMeal"
              v-bind:key="indexes"
              class="chanpinclasses"
            >
              <div @click="clicktao(itemes.id)" class="chanpinclass">
                <div class="chanimg">
                  <img :src="itemes.cover" />
                </div>
                <div class="chanrightclass">
                  <div class="now">{{ itemes.set_meal_name }}</div>
                  <div>{{ itemes.fit_type }}</div>
                  <div>
                    <div></div>
                  </div>
                  <div>{{ itemes.fit_post }}</div>
                  <div>
                    <div class="yjcla">
                      <div><span>¥</span>{{ " " + itemes.price }}</div>
                      <div class="oldmoney">
                        {{ "¥ " + itemes.reference_price }}
                      </div>
                    </div>
                    <div class="xiangqingclass">
                      <!-- <div>详情</div>
                      <van-icon size=".25rem" color="#FE5903" name="arrow" /> -->
                      <div class="xiaos">月售{{ itemes.new_sales }}份</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <van-empty
          v-show="taoarray.length < 1 && isload != 0"
          :image="imges"
          description="一条数据也没有"
        />
      </div>

      <!-- 一级菜单点击展示 -->
      <van-popup
        v-model="oneMenuShow"
        round
        position="bottom"
        :style="{ height: '50%' }"
      >
        <div style="padding: 0.36rem 0.3rem">
          <div class="alllei">全部类型</div>
          <div class="themenuone">
            <div
              @click="getthemsg(item)"
              v-for="(item, index) in taoarray"
              v-bind:key="index"
              :class="
                setwomenu == item.industry_name
                  ? 'menuchildclass4 menuchildclass5'
                  : 'menuchildclass4'
              "
            >
              {{ item.industry_name }}
            </div>
          </div>
        </div>
      </van-popup>
    </div>
    <sharepage></sharepage>
    <div style="height:1.3rem"></div>
  </div>
</template>
<script>
import sharepage from "../../views/sharepage/index";
import eventbus from "../../../util/eventbus";
export default {
  data() {
    return {
      menulist: [],
      taoarray: "", //套餐数据信息
      imges: require("../../assets/nomsg.png"),
      isload: 0,
      sqmsg: "收起使用流程",
      sqstyle: "height:3rem",
      sqstyle2: "",
      issq: true,
      showmsg: true,
      seindex: "-1",
      seindex2: "-1",
      showmask: false, //是否显示遮罩层
      showmenu: false, //是否展示选择菜单
      menuArray: [], //二级类目展示
      taoarray_two: [],
      setwomenu: "-1", //选择的二级菜单
      seindex3: "0",
      showother: false,
      oneMenuShow: false,
      lock: true,
    };
  },
  components: {
    sharepage,
  },
  beforeCreate() {},
  created() {
    this.tosharemeth();
    this.sqzkmeth();
  },
  mounted() {
    this.addonelei();
    
    eventbus.$on("zhankai", () => {
      if (this.lock == true) {
        this.lock = false;
        this.sqmeth();
      }
    });
  },
  methods: {
    //分享内容
    tosharemeth() {
      let obj = JSON.stringify({
        sharetitle: "点我！秒变背景调查专家",
        sharedesc: "比招人更难的是招到靠谱的人",
        isshare: "1",
        share_phone: localStorage.getItem("phone"),
      });
      localStorage.setItem("shareobj", obj);
    },
    /**
     * 点击套餐
     */
    clicktao(id) {
      this.$router.push({
        path: "/postfive",
        query: {
          id,
          isqrcode: this.$route.query.iscreate,
        },
      });
    },
    clickleft() {
      this.$router.push("/home");
    },
    //点击收起
    sqmeth() {
      this.issq = !this.issq;
      this.sqzkmeth();
    },

    //展开收起管理
    sqzkmeth() {
      if (this.issq) {
        this.sqstyle = "height:0;padding-top:0;overflow:hidden;";
        this.showmsg = false;
      } else {
        this.sqstyle = "height:2rem";
        this.showmsg = true;
      }
    },

    /**
     * 获取所有一级类目
     */
    addonelei() {
      this.$http
        .post("/firm/v1/Product/enableLists1", {
          reqType: "product_base",
        })
        .then((res) => {
          let list = JSON.parse(res.data).data.classifyList1;
          this.menulist = list;
          this.tabclick(0);
        });
    },

    /**
     * 根据选择菜单过滤数据
     */
    filterMsg(allmsg, msg) {
      return allmsg.filter((e) => {
        return e == msg;
      });
    },

    /**
     * 关闭遮罩层和下拉二级菜单
     */
    closeOrOpenMask(bool) {
      this.noScrollingDom(bool);
      this.showmask = bool;
      this.showmenu = bool;
    },

    /**
     * 点击一级菜单
     */
    tabclick(e) {
      this.seindex3 = e;
      if (!this.isRepeatClickOneMenu(e)) return;
      this.seindex = e;
      this.getleimsg(this.menulist[e].id);
      if (this.menulist[e].id) {
        this.closeOrOpenMask(true);
      } else {
        this.closeOrOpenMask(false);
      }
    },

    /**
     * 点击二级菜单
     */
    getthemsg(semsg) {
      // console.error(semsg);
      this.oneMenuShow = false;
      this.isRepeatClickOneMenu(this.seindex);
      let themsg = this.filterMsg(this.taoarray, semsg);
      this.menu2Class(semsg);
      // console.error(themsg);
      this.upView(themsg);
      this.closeOrOpenMask(false);
    },

    /**
     * 更新视图数据
     */
    upView(msg) {
      this.taoarray_two = msg;
    },

    /**
     * 禁止页面滚动
     */
    noScrollingDom(bool) {
      if (bool) {
        // document.body.style = "position: fixed;width: 100%;";
      } else {
        document.body.style = "";
      }
    },

    /**
     * 二级菜单选中的名称（非对象）
     */
    menu2Class(msg) {
      return (this.setwomenu = msg.industry_name);
    },

    /**
     * 重复点击一级数据
     */
    isRepeatClickOneMenu(e) {
      if (this.seindex == e && e != 0) {
        this.seindex = !e;
        this.seindex2 = e;
        this.closeOrOpenMask(false);
        return false;
      } else {
        this.seindex2 = "-1";
        return true;
      }
    },

    /**
     * 是否点击的全部数据
     */
    isGetAllMsg(bool) {
      if (!bool) {
        this.upView(this.taoarray);
        this.seindex = "-1";
        this.seindex2 = "-1";
        return true;
      } else {
        return false;
      }
    },

    /**
     * 是否显示加载更多（一级菜单）
     */
    isShowOther() {
      setTimeout(() => {
        let dom = document.getElementsByClassName("menuclass_1")[0];
        if (dom.scrollWidth > dom.clientWidth) {
          this.showother = true;
        } else {
          this.showother = false;
        }
      }, 100);
    },

    /**
     * 获取分类套餐
     */
    getleimsg(id) {
      this.$http
        .post("/firm/v1/Product/lists", {
          reqType: "product",
          classifyId: id,
        })
        .then((res) => JSON.parse(res.data).data)
        .then((res) => {
          // console.log(res);
          this.isload++;
          this.taoarray = res.conditionList;
          this.isShowOther();
          this.getthemsg(this.taoarray[0]);
        });
    },
  },
};
</script>
<style scoped>
.imgback{
  position: relative;
  margin-left: -.2rem;
  padding: .09rem 0;
  z-index: 1001;
  background-color: #ffffff;
  box-shadow: -.3rem 0 .3rem #ffffff;
}
.menuthecla {
  padding: 0 0.32rem;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.alllei {
  text-align: center;
  font-size: 0.36rem;
  font-weight: 500;
  color: #303133;
  line-height: 0.48rem;
  margin-bottom: 0.4rem;
}
.themenuone {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.theallmsgclass >>> .van-overlay {
  top: 0 !important;
}
.otherdiv {
  right: 0;
  background-color: #ffffff;
  padding: 0.1rem 0.3rem;
}
.otherclass {
  width: 0.42rem;
  height: 0.32rem;
}
.menuclass_1 {
  position: relative;
  display: flex;
  overflow: auto;
  align-items: center;
  white-space: nowrap;
  margin-bottom: 0.02rem;
  padding: 0.22rem 0;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  background-color: #ffffff;
  z-index: 1000;
}
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.oldmoney {
  text-decoration: line-through;
  font-size: 0.24rem;
  font-weight: 400;
  color: #c0c4cc;
  line-height: 0.34rem;
  margin-left: 0.1rem;
}

.theallmsgclass >>> .van-tab {
  font-size: 0.28rem;
  font-weight: 400;
  color: #606266;
  line-height: 0.48rem;
}
.theallmsgclass >>> .van-tab--active {
  font-size: 0.32rem;
  font-weight: 800;
  color: #303133;
  line-height: 0.48rem;
}
.xiangqingclass {
  display: flex;
  align-items: center;
  font-size: 0.24rem;
  font-weight: 400;
  color: rgba(254, 89, 3, 0.75);
  line-height: 0.34rem;
}
.msgclass {
}
.yjcla span {
  font-size: 0.28rem;
}
.topall {
  position: relative;
  border-top-color: #eeeeee;
  border-top-style: solid;
  border-top-width: 1px;
  border-bottom-color: #eeeeee;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.menuchildclass {
  padding: 0.02rem 0.36rem;
  border-radius: 0.36rem;
  background-color: #f2f4f4;
  margin-right: 0.14rem;

  font-size: 0.26rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #606266;
  line-height: 0.62rem;
}
.menuchildclass2 {
  background-color: #fef3ec;
  color: #fe5903;
}
.menuchildclass4 {
  padding: 0.08rem 0;
  border-radius: 0.36rem;
  background-color: #f2f4f4;
  min-width: 3.3rem;
  font-size: 0.26rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #606266;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 0.62rem;
  margin-bottom: 0.22rem;
}
.menuchildclass5 {
  background-color: #fef3ec;
  color: #fe5903;
}

.menuclass > div:nth-child(2n + 1) {
  margin-right: 2.9%;
}
.menuclass {
  background-color: #ffffff;
  padding: 0.36rem 4%;
  padding-bottom: 0;
  position: absolute;
  bottom: auto;
  height: auto;
  background-color: #ffffff;
  width: 100%;
  z-index: 2000;
  overflow-y: auto;
  animation-duration: 0.3s;
  animation-name: slidein;
  max-height: 5rem;
  min-height: 0;
}
@keyframes slidein {
  from {
    height: 0rem;
    max-height: 5rem;
    min-height: 0;
  }

  to {
    height: 1.5rem;
    max-height: 5rem;
    min-height: 0;
  }
}
.sq {
  margin-top: 1.4rem;
  text-align: center;
  font-size: 0.28rem;
  line-height: 0.36rem;
  display: flex;
  align-items: center;
  color: #4571d0;
  justify-content: center;
}
.sq > :first-child {
  margin-right: 0.1rem;
}
.toall {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 0.32rem;
  margin-bottom: 0.3rem;
}
.toall > :nth-child(even) {
  width: 0.44rem;
  margin: 0 0.14rem;
  padding-top: 0.45rem;
}
.toall > :nth-child(odd) {
  position: relative;
  padding: 0.16rem 0.36rem;
  background-color: #ffffff;
  border-radius: 50%;
  font-size: 0.4rem;
  line-height: 0.6rem;
  color: #ff6d14;
  box-shadow: 0rem 0.1rem 0.3rem 0rem #ececec;
}
.toall > :first-child {
  padding: 0.16rem 0.39rem;
}

.toall > :first-child::after {
  position: absolute;
  content: "完成";
  display: block;
  font-size: 0.24rem;
  line-height: 0.36rem;
  color: #606266;
  width: 1.68rem;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  bottom: -0.58rem;
}
.toall > :first-child::before {
  position: absolute;
  content: "企业认证";
  display: block;
  font-size: 0.24rem;
  line-height: 0.36rem;
  color: #606266;
  width: 1.68rem;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  bottom: -0.98rem;
}
.toall > :nth-child(3)::after {
  position: absolute;
  content: "选择产品包";
  display: block;
  font-size: 0.24rem;
  line-height: 0.36rem;
  color: #606266;
  width: 1.68rem;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  bottom: -0.58rem;
}
.toall > :nth-child(3)::before {
  position: absolute;
  content: "并发起授权";
  display: block;
  font-size: 0.24rem;
  line-height: 0.36rem;
  color: #606266;
  width: 1.68rem;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  bottom: -0.98rem;
}
.toall > :nth-child(5)::after {
  position: absolute;
  content: "候选人";
  display: block;
  font-size: 0.24rem;
  line-height: 0.36rem;
  color: #606266;
  width: 1.38rem;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  bottom: -0.58rem;
}
.toall > :nth-child(5)::before {
  position: absolute;
  content: "确认授权";
  display: block;
  font-size: 0.24rem;
  line-height: 0.36rem;
  color: #606266;
  width: 1.38rem;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  bottom: -0.98rem;
}
.toall > :nth-child(7)::after {
  position: absolute;
  content: "背调报告";
  display: block;
  font-size: 0.24rem;
  line-height: 0.36rem;
  color: #606266;
  width: 1.68rem;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  bottom: -0.58rem;
}
.toall > :nth-child(7)::before {
  position: absolute;
  content: "质检后交付";
  display: block;
  font-size: 0.24rem;
  line-height: 0.36rem;
  color: #606266;
  width: 1.68rem;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  bottom: -0.98rem;
}
.limc {
  margin: 0 0.34rem;
  text-align: center;
  padding: 0.1rem;
  border-radius: 0.34rem;
  border: 0.02rem solid rgba(254, 89, 3, 0.75);

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(254, 89, 3, 0.75);
  line-height: 0.36rem;
}
.liucheng {
  background-color: #ffffff;
  padding: 0.28rem 0;
  padding-bottom: 0;
  padding-top: 0.08rem;
  overflow: hidden;
}
.theallmsgclass >>> .van-sticky--fixed {
  z-index: 1100;
}
.theallmsgclass {
  background-color: #ffffff;
  overflow: hidden;
  min-height: 100%;
}
.xiaos {
  font-size: 0.24rem;
  font-weight: 400;
  color: #909399;
  line-height: 0.34rem;
}
.yjcla {
  display: flex;
  align-items: center;
}
.now {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  min-height: 0.86rem;
}
.van-empty >>> .van-empty__image {
  width: 100px;
}
.chanrightclass > div:nth-child(5) {
  margin-top: 0.28rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff6d20;
  line-height: 0.44rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chanrightclass > div:nth-child(4) {
  font-size: 0.18rem;
  line-height: 0.25rem;
  color: #f55814;
  margin-bottom: 0.19rem;
  margin-top: 0.19rem;
  background-color: #fff2e0;
  display: inline-block;
  padding: 0.06rem 0.1rem;
  display: none;
}
.chanrightclass > div:nth-child(3) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chanrightclass > div:nth-child(2) {
  color: #797979;
  font-size: 0.24rem;
  line-height: 0.38rem;
  margin: 0.08rem 0;
  width: 4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chanrightclass > div:first-child {
  font-size: 0.32rem;
  line-height: 0.4rem;
  color: #222222;
  font-weight: bold;
}
.chanrightclass {
  width: 100%;
  padding-left: 0.22rem;
}
.chanimg img {
  width: 2.12rem;
  min-width: 2.12rem;
  height: 2.12rem;
  border-radius: 0.18rem;
}
.chanpinclasses {
  background-color: #ffffff;
  /* margin-bottom: 0.3rem .28rem; */
  padding: 0.3rem 0;
  border-bottom-color: #f6f6f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.chanpinclass {
  display: flex;
  border-radius: 0.2rem;
  overflow: hidden;
}
.themenuall {
  padding: 0.24rem 0.3rem;
  padding-top: 0;
  background: #ffffff;
}
.dianclass2 {
  width: 0.12rem;
  height: 0.12rem;
  background-color: #ff3b00;
  border-radius: 50%;
  margin-left: -0.39rem;
  margin-right: 0.24rem;
  z-index: 1000;
}
.dianclass {
  width: 0.12rem;
  height: 0.12rem;
  background-color: #ff3b00;
  border-radius: 50%;
  border: 0.13rem solid #f6dad3;
  margin-right: 0.14rem;
  animation: myfirst2 5s infinite;
}
@keyframes myfirst2 {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(0.1);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(0.1);
  }
}
@keyframes myfirst {
  0% {
    border: 0.13rem solid #f6dad3;
    margin-right: 0.14rem;
    margin-left: 0;
  }
  25% {
    border: 0.07rem solid #f6dad3;
    margin-right: 0.2rem;
    margin-left: 0.06rem;
  }
  50% {
    border: 0.01rem solid #f6dad3;
    margin-right: 0.26rem;
    margin-left: 0.12rem;
  }
  75% {
    border: 0.07rem solid #f6dad3;
    margin-right: 0.2rem;
    margin-left: 0.06rem;
  }
  100% {
    border: 0.13rem solid #f6dad3;
    margin-right: 0.14rem;
    margin-left: 0rem;
  }
}

.menuclassthe {
  display: flex;
  align-items: center;

  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.48rem;
  margin: 0.4rem 0;
}
.menuclasses {
  display: flex;
  align-items: center;
}
.iconclass {
  width: 0.35rem;
  margin-right: 0.1rem;
  margin-top: -0.08rem;
}
.tohomeclass {
  color: #f9814f;
}
.theallmsgclass >>> .van-overlay {
  top: 20%;
  height: 80%;
}
</style>